.App {
  text-align: center;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#box {
  height: 856px;
  width: 828px;
  margin: auto;
  background: url(../public/assets/Jackpot.png);
  margin-top: 400px;
  display: flex;
  flex-direction: row;
}

.lamelles {
  width: 630px;
  margin-left: 54px;
  margin-top: 340px;
  height: 260px;
  display: flex;
}

.lamelle {
  flex: 1;
  background: url(../public/assets/Roller.png) repeat-y;
  z-index: -1;
}

#lam1 {
  transition-duration: 6000ms;
}

#lam2 {
  transition-duration: 8000ms;
}

#lam3 {
  transition-duration: 10000ms;
}

.form {
  width: 740px;
  margin: auto;
  margin-top: -700px;
  padding: 20px;
  border-radius: 20px;
  border: #BB7454 6px solid;
  background: #DCA682;
  z-index: 50;
  position: absolute;
  left: 170px;
  box-shadow: 16px 16px 16px rgba(0, 0, 0, 0.4);
}

.input {
  width: 80%;
  margin: auto;
  font-size: 36px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.4);
  margin-top: 40px;
}

#submit {
  padding: 10px;
  margin-top: 40px;
  background: #BB7454;
  border: none;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 300px;
  font-size: 36px;
  font-weight: bold;
  color: white;
}